import React from 'react'

import './grid-banner.css'

const ColumnContent = (props) => (
    <div className="grid-banner">
        <div className="container">
            { props.children }
        </div>
    </div>
)

export default ColumnContent
