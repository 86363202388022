import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'
import Fade from 'react-reveal/Fade'
import { Grid, GridColumn, GridRow } from '../Grid'

const WelcomeAbout = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
            frontmatter {
              about_welcome {
                title
                body
                image
              }
            }
          }
        }
        `}
        render={ (data) => {
          const WelcomeAboutData = data.markdownRemark.frontmatter.about_welcome
          const converter = new showdown.Converter()
          
            return(
                <Grid id="trajetoria-de-sucesso">
                  <GridRow> 
                    <Fade bottom cascade>
                      <GridColumn moreClass="grid-col--seven grid-col--ma">
                          <h3>{ WelcomeAboutData.title }</h3>
                          <br />
                          <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(WelcomeAboutData.body) }}></div>
                      </GridColumn>
                    </Fade>
                  </GridRow>
                </Grid>
            )
        }}
  ></StaticQuery>
)

export default WelcomeAbout
