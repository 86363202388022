import React from 'react'
import Fade from 'react-reveal/Fade'
import Image from '../Helpers/image'

import './welcome.scss'
import { Link } from 'gatsby'

const Welcome = (props) => {
    return(
        <Fade bottom cascade>
            <div className="welcome">
                <div className="welcome__column-image">
                    <Image filename={ props.image } />
                </div>

                <div className="container">
                    <div className="welcome__column">
                        { props.subtitle && 
                            <p><Link to={ props.subtitleLink }>{ props.subtitle }</Link></p>                    
                        }

                        <h2>{ props.title }</h2>
                        <p>{ props.content }</p>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default Welcome
