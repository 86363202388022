import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from '../Helpers/image'
import showdown from 'showdown'
import { Grid, GridColumn, GridRow } from '../Grid'
import Fade from 'react-reveal/Fade'

const WelcomeAbout = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
            frontmatter {
              about_culture {
                title
                body
                image
              }
            }
          }
        }
        `}
        render={ (data) => {
          const CultureAboutData = data.markdownRemark.frontmatter.about_culture
          const converter = new showdown.Converter()

            return(
                <Grid id="cultura-organizacional">
                  <GridRow>
                    <GridColumn moreClass="grid-col--four">
                      <Fade bottom>
                        <h3>{ CultureAboutData.title }</h3>

                        <figure>
                            <Image filename={ CultureAboutData.image } />
                        </figure>
                      </Fade>
                    </GridColumn>
        
                    <GridColumn moreClass="grid-col--five">
                      <Fade bottom>
                        <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(CultureAboutData.body) }}></div>
                      </Fade>
                    </GridColumn>
                  </GridRow>
                </Grid>
            )
        }}
  ></StaticQuery>
)

export default WelcomeAbout
