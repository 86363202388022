import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'
import { Grid, GridColumn, GridRow } from '../Grid'
import Image from '../Helpers/image'
import Fade from 'react-reveal/Fade'
import './awards.scss'

const AwardAbout = (props) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(awards)/"}}, sort: {fields: frontmatter___order}) {
          edges {
            node {
              id
              frontmatter {
                image
                title
              }
              html
            }
          }
        }
        markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
          frontmatter {
            about_awards {
              title
              body
            }
          }
        }
      }
    `}
    render={ (data) => {
      const AwardsData = data.allMarkdownRemark
      const AwardsAboutData = data.markdownRemark.frontmatter.about_awards
      const converter = new showdown.Converter()
      
        return(
          <>
            <Fade bottom cascade>
              <Grid id="premios-certificacao">
                <GridRow>
                  <GridColumn>
                    <h3>{ AwardsAboutData.title }</h3>
                  </GridColumn>

                  <GridColumn>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(AwardsAboutData.body) }}></div>
                  </GridColumn>
                </GridRow>
              </Grid>

              <div className="awards">
                  { AwardsData.edges.map(edge => (
                    <div className="awards__item" key={ edge.node.id }>
                      <figure>
                        <Image filename={ edge.node.frontmatter.image } alt={ edge.node.frontmatter.title } />
                      </figure>
                      <h4>{ edge.node.frontmatter.title }</h4>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.html) }} />
                    </div>
                  ))}
              </div>
            </Fade>
          </>
        )
    }}
  ></StaticQuery>
)

export default AwardAbout
