import React from 'react'

import './grid.scss'

const Grid = (props) => (
    <div id={ props.id } className={ `grid ${ props.moreClass }` }>
        <div className="grid-container">
            { props.children }
        </div>
    </div>
)

const GridRow = (props) => (
    <div className={ `grid-row ${ props.moreClass }` }>
        { props.children }
    </div>
)

const GridColumn = (props) => (
    <div className={ `grid-col ${ props.moreClass }` }>
        { props.children }
    </div>
)

export { Grid, GridColumn, GridRow }
