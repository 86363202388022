import React from 'react'
import { graphql } from 'gatsby'

import AwardAbout from '../components/AwardAbout'
import CultureAbout from '../components/CultureAbout'
import HistoryAbout from '../components/HistoryAbout'
import Layout from '../components/Layout'
import PoliticsAbout from '../components/PoliticsAbout'
import PrinciplesAbout from '../components/PrinciplesAbout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import WelcomeAbout from '../components/WelcomeAbout'

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
      frontmatter {
        seo {
          seo_description
          seo_title
        }
        welcome {
          welcome_image
          welcome_content
          welcome_title
        }
      }
    }
  }
`
const Company = ({ data }) => {
  const AboutData = data.markdownRemark.frontmatter

  return(
      <Layout>
        <SEO title={ AboutData.seo.seo_title  } description={ AboutData.seo.seo_description }/>
      
        <Welcome
          title={ AboutData.welcome.welcome_title }
          image={ AboutData.welcome.welcome_image }
          content={ AboutData.welcome.welcome_content } />

        <WelcomeAbout />
        <HistoryAbout />
        <CultureAbout />
        <PoliticsAbout />
        <PrinciplesAbout />
        <AwardAbout />
      </Layout>
  )
}

export default Company
