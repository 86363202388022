import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'
import { Grid, GridColumn, GridRow } from '../Grid'
import Fade from 'react-reveal/Fade'

const PrinciplesAbout = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
            frontmatter {
              about_principles {
                title
                block_one
                block_two
                block_three
                block_four
                block_five
                block_six
                block_seven
                block_eight
              }
            }
          }
        }
        `}
        render={ (data) => {
          const PrinciplesAboutData = data.markdownRemark.frontmatter.about_principles
          const converter = new showdown.Converter()

            return(
              <Fade bottom cascade>
                <Grid moreClass="grid--bordered">
                  <GridRow>
                    <GridColumn>
                      <h4>{ PrinciplesAboutData.title }</h4>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_one) }} ></div>
                    </GridColumn>

                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_two) }} ></div>
                    </GridColumn>

                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_three) }} ></div>
                    </GridColumn>
                  </GridRow>

                  <GridRow>
                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_four) }} ></div>
                    </GridColumn>

                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_five) }} ></div>
                    </GridColumn>

                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_six) }} ></div>
                    </GridColumn>
                  </GridRow>

                  <GridRow>
                    <GridColumn moreClass="grid-col--1of3">
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_seven) }} ></div>
                    </GridColumn>

                    <GridColumn>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PrinciplesAboutData.block_eight) }} ></div>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Fade>
            )
        }}
  ></StaticQuery>
)

export default PrinciplesAbout
