import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'
import { Grid, GridColumn, GridRow } from '../Grid'
import Image from '../Helpers/image'
import Fade from 'react-reveal/Fade'

const PoliticsAbout = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
            frontmatter {
              about_politic {
                title
                body
                image
              }
            }
          }
        }
        `}
        render={ (data) => {
          const PoliticAboutData = data.markdownRemark.frontmatter.about_politic
          const converter = new showdown.Converter()

          return(
              <Grid id="trajetoria-de-sucesso">
                <GridRow>
                  <GridColumn>
                    <Fade bottom>
                      <h4>{ PoliticAboutData.title }</h4>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PoliticAboutData.body) }}></div>
                    </Fade>
                  </GridColumn>
      
                  <GridColumn>
                    <Fade bottom>
                      <figure>
                          <Image filename={ PoliticAboutData.image } />
                      </figure>
                    </Fade>
                  </GridColumn>
                </GridRow>
              </Grid>
          )
        }}
  ></StaticQuery>
)

export default PoliticsAbout
