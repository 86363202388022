import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import showdown from 'showdown'
import GridBanner from '../GridBanner'
import Fade from 'react-reveal/Fade'

const HistoryAbout = (props) => (
    <StaticQuery
        query={graphql`
        {
          markdownRemark(fileAbsolutePath: {regex: "/(about/_index)/"}) {
            frontmatter {
              about_history {
                body
              }
            }
          }
        }
        `}
        render={ (data) => {
          const HistoryAboutData = data.markdownRemark.frontmatter.about_history
          const converter = new showdown.Converter()

          return(
            <Fade>
              <GridBanner>
                <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(HistoryAboutData.body) }}></div>
              </GridBanner>
            </Fade>
          )
        }}
  ></StaticQuery>
)

export default HistoryAbout
